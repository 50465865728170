/* eslint-disable @typescript-eslint/no-empty-function */
import { TaxCreditSettings } from '@shared/schema/settings';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import React, { createContext, useContext, useEffect, useState } from 'react';

export const SETTINGS_COLLECTION = 'settings';
export const TAX_CREDIT_SETTINGS_DOC = 'taxCreditForHouseholdExpenses';

export interface SettingsAppContext {
  taxCreditSettings: TaxCreditSettings | undefined;
}

const SettingsContext = createContext<SettingsAppContext>({
  taxCreditSettings: undefined,
});

interface SettingsProviderTypes {
  settings: SettingsAppContext;
  children: JSX.Element | JSX.Element[];
}

export const defaultSettingsState: SettingsAppContext = {
  taxCreditSettings: undefined,
};

// at least here we separate the logic and the UI, with this simple Context API
export const SettingsProvider = ({
  settings, children,
}: SettingsProviderTypes) => {
  const [taxCreditSettings, setTaxCreditSettings,] = useState<TaxCreditSettings | undefined>(settings.taxCreditSettings);
  const firestore = getFirestore(firebaseApp());

  useEffect(() => {
    // only fetch settings once and provide the existing object to the consumers when it already exists
    if (!taxCreditSettings) {
      const getTaxCreditSettings = async () => {
        const taxCreditSettingsRef = doc(firestore, SETTINGS_COLLECTION, TAX_CREDIT_SETTINGS_DOC);
        const docSnap = await getDoc(taxCreditSettingsRef);

        if (docSnap.exists()) {
          const settingsData = docSnap.data();
          setTaxCreditSettings(settingsData as TaxCreditSettings);
        }
      };

      getTaxCreditSettings();
    }
  }, [firestore, taxCreditSettings,]);

  return <SettingsContext.Provider value={{
    taxCreditSettings,
  }}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => useContext(SettingsContext);

export default SettingsContext;
