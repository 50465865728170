/* eslint-disable sonarjs/no-duplicate-string */

import { Theme as MaterialTheme, PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette';

type RuraliaTheme = MaterialTheme & RuraliaThemeExtensions;

interface RuraliaPalette extends PaletteOptions {
  background: Partial<TypeBackground> & {
    /**
     * The background color of papers on the top of a background paper
     */
  };
  text: Partial<TypeText> & {
    lightgrey: string;
  }
}
interface RuraliaThemeExtensions {
  animations: {
    hovers: {
      /**
       * Hover on card defining some basic concept on the UI like
       * project, query or question.
       */
      card?: string;
    }
  },
  palette: RuraliaPalette;
  constants: {
    headerHeight: string;
    bottomHeight: string
  }
}
/**
 * Cast Emotion's Theme type to Material theme
 */
declare module '@emotion/react' {
  export interface Theme extends RuraliaTheme {
    custom: string; // Needed to make
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body14: React.CSSProperties;
    body16: React.CSSProperties;
    body18: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    body14?: React.CSSProperties;
    body16?: React.CSSProperties;
    body18?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body14: true;
    body16: true;
    body18: true;
  }
}

type RuraliaOptions = ThemeOptions & RuraliaThemeExtensions;

const ITEM_HOVER_STYLE = 'transform: scale(1.03);' +
  'box-shadow: 0px 10px 13px -7px #000000, 11px 15px 15px 3px rgba(0,0,0,0);';

const {
  palette,
} = createTheme();
let theme = createTheme();

const themeOptions: RuraliaOptions = {
  spacing: 8,
  animations: {
    hovers: {
      card: ITEM_HOVER_STYLE,
    },
  },
  shape: {
    borderRadius: '2px',
  },
  shadows: Array(25).fill('none') as Shadows,
  constants: {
    headerHeight: theme.breakpoints.down('sm') ? '100px' : '122px',
    bottomHeight: '72px',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          margin: 0;
        }
        body {
          margin: 0;
        }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'borderRadius': 2,
          'padding': '8px 16px',
          'boxShadow': '0px 1px 2px #00000040',
          'textTransform': 'uppercase',
          'textAlign': 'center',
          'whiteSpace': 'nowrap',
          'fontWeight': 300,
          'letterSpacing': '0.5px',
          '&.Mui-disabled': {
            'backgroundColor': '#D6D6D6',
            'color': '#6A6A6A',
          },
        },
        text: {
          'color': '#020202',
          'backgroundColor': '#D8D3A233',
          ':hover': {
            'backgroundColor': '#91A189',
            'color': '#FFFFFF',
          },
        },
        contained: {
          'color': '#FFFFFF',
          'backgroundColor': '#91A189',
          ':hover:not(.button-contained-lightgrey)': {
            'backgroundColor': '#6A7965',
            'boxShadow': '0px 1px 2px #00000040',
          },
          '&.button-contained-white': {
            'color': '#020202',
            'backgroundColor': '#FFFFFF',
            ':hover': {
              'backgroundColor': '#EBE7CC !important',
              'boxShadow': '0px 1px 2px #00000040',
            },

          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'height': '46px',
          'width': '46px',
          'backgroundColor': 'transparent',
          'padding': 0,
          'objectFit': 'contain',
          '& > .MuiSvgIcon-root': {
            width: '1.2em',
            height: '1.2em',
            color: '#91A189',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#E6E6E6',
          margin: '0',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF !important',
          borderRadius: '0px !important',
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'padding': '1rem',
          'backgroundColor': '#FFFFFF',
          ':hover': {
            'backgroundColor': '#F8F8F8',
          },
          '&.Mui-selected': {
            'backgroundColor': '#91A189',
            'color': '#FFFFFF',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          opacity: 0.9,
        },
        paper: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Helvetica',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: '#020202',
      margin: '0 !important',
    },
    h2: {
      fontFamily: 'Yu Gothic',
      fontSize: '2rem',
      letterSpacing: '0px',
      lineHeight: '2rem',
    },
    h3: {
      fontFamily: 'Yu Gothic',
      fontSize: '1.5rem',
      letterSpacing: '0px',
      lineHeight: '1.5rem',
      textAlign: 'center',
    },
    body18: {
      'fontSize': '1.125rem',
      'fontWeight': 400,
      'letterSpacing': '0.5px',
      'lineHeight': '1.5rem',
      'display': 'flex',
      'alignItems': 'center',
    },
    body16: {
      'fontSize': '1rem',
      'fontWeight': 400,
      'letterSpacing': '0.5px',
      'lineHeight': '1.5rem',
    },
    body14: {
      'fontSize': '0.875rem',
      'fontWeight': 400,
      'letterSpacing': '0.5px',
      'lineHeight': '1.5rem',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#F5F2DF',
      contrastText: '#020202',
    },
    secondary: {
      main: '#91A189',
      contrastText: '#FFFFFF',
    },
    background: {
      default: '#F5F2DF',
      paper: '#FEFEFC',
    },
    text: {
      primary: '#020202',
      secondary: '#E5E5E5',
      lightgrey: '#D3D3D3',
    },
    darkgreen: palette.augmentColor({
      color: {
        main: '#6A7965',
      },
    }),
    red: palette.augmentColor({
      color: {
        main: '#821201',
      },
    }),
  },
};

// Create a theme instance.
theme = createTheme(themeOptions) as RuraliaTheme;

export default theme;
