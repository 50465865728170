/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState } from 'react';

export interface AppAudioContext {
  audioOn: boolean;
  audioMainControlOn: boolean;
  dialogAudioOn: boolean;
  setAudioOn: (arg: boolean) => void;
  setAudioMainControlOn: (arg: boolean) => void;
  setDialogAudioOn: (arg: boolean) => void;
}

interface AudioProviderTypes {
  audio: AppAudioContext,
  children: JSX.Element | JSX.Element[];
}

const AudioContext = createContext<AppAudioContext>({
  audioOn: false,
  audioMainControlOn: false,
  dialogAudioOn: false,
  setAudioOn: () => { },
  setAudioMainControlOn: () => { },
  setDialogAudioOn: () => { },
});

export const defaultAudioState: AppAudioContext = {
  audioOn: false,
  audioMainControlOn: false,
  dialogAudioOn: false,
  setAudioOn: () => { },
  setAudioMainControlOn: () => { },
  setDialogAudioOn: () => { },
};

export const AudioProvider = ({
  audio,
  children,
}: AudioProviderTypes) => {
  const [audioOn, setAudioOn,] = useState<boolean>(audio.audioOn);
  const [audioMainControlOn, setAudioMainControlOn,] = useState<boolean>(audio.audioMainControlOn);
  const [dialogAudioOn, setDialogAudioOn,] = useState<boolean>(audio.dialogAudioOn);

  return <AudioContext.Provider value={{
    audioOn,
    audioMainControlOn,
    dialogAudioOn,
    setAudioOn,
    setAudioMainControlOn,
    setDialogAudioOn,
  }}>{children}</AudioContext.Provider>;
};

export const useAudio = () => useContext(AudioContext);

export default AudioContext;
