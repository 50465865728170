/**
 * # WEB DEVELOPMENT
 *
 * @copyright Mindhive Oy 2021-2022
 */
import { AppConfig } from '@shared/schema';

const config: AppConfig = {
  environment: {
    target: 'production',
    build: '$BUILD_NUMBER',
    version: '$VERSION_NUMBER',
    sha1: '',
    branch: '',
    tag: '',
    webHostAddress: 'https://ruralia.fi',
  },
  firebase: {
    apiKey: 'AIzaSyDe1AjmCZdk0WjetcWeWAu-1_8BWLx4hKA',
    authDomain: 'common-development-project.firebaseapp.com',
    projectId: 'common-development-project',
    storageBucket: 'common-development-project.appspot.com',
    messagingSenderId: '877062671345',
    appId: '1:877062671345:web:0ddeb2d0fb5d6593079250',
    measurementId: 'G-13XMWLRT70',
  },
  google: {
    credentials: {
      android: '',
      ios: '',
    },
    apiKeys: {
      map: '',
      distanceMatrix: '',
    },
  },
  facebook: {},
  sanity: {
    projectId: 'uoysl11g',
    dataset: 'production',
    useCdn: false,
    apiVersion: '2021-08-31',
  },
  sentry: {
    dsn: '',
  },
  messaging: {
    vapidKey: 'TODO',
  },

};

export default config;
