import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CookieConsent from 'react-cookie-consent';
import Link from 'next/link';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Close = styled(CancelIcon)(({
  theme,
}) => `
  color: ${theme.palette.secondary.main};
`);

const CookieConsentComponent = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const cookieConsentRef = useRef(null);

  const {
    t,
  } = useTranslation();

  const consentStyle = useMemo(() => {
    return {
      textAlign: isMobile ? 'left' : 'center',
      alignItems: 'center',
      background: theme.palette.background.paper,
      color: '#020202',
      padding: isMobile ? '0.3rem' : '1rem',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1rem',
    };
  }, [isMobile,]);

  const textStyle = useMemo(() => {
    return {
      gap: isMobile ? '0.8rem' : '0.7rem',
      maxWidth: '90%',
      fontSize: isMobile ? '0.75rem' : 'inherit',
      color: theme.palette.text.primary,
    };
  }, [isMobile,]);

  const linkStyle = useMemo(() => {
    return {
      zIndex: 1,
      cursor: 'pointer',
      width: isMobile ? '100%' : 'fit-content',
      fontSize: isMobile ? '0.75rem' : 'inherit',
      color: theme.palette.text.primary,
      textDecoration: 'underline',
      textUnderlineOffset: '2px',
    };
  }, [isMobile,]);

  // Custom accept button needs an own click handler, default won't work
  const onAccept = useCallback((): void => {
    cookieConsentRef?.current?.accept();
  }, []);

  const AcceptButton = useCallback((): JSX.Element => {
    return <Button style={{
      position: 'relative',
      background: 'transparent',
      boxShadow: 'none',
    }} onClick={onAccept}>
      <Close />
    </Button>;
  }, [onAccept,]);

  return <CookieConsent
    buttonWrapperClasses='consent-icon'
    ref={cookieConsentRef}
    buttonStyle={{
      display: 'none',
    }}
    cookieName="cookiesAccepted-sptrengtporten"
    ariaAcceptLabel={t('An accept button that closes the notification for the site using cookies')}
    ButtonComponent={AcceptButton}
    style={consentStyle}
    expires={181} // six months, the maximum usual recommended time for renewing the consent
  >
    <Typography
      variant='body18'
      sx={textStyle}>
      {t('We use cookies to enable an improved browsing experience, and to share information with our marketing partners')}.
    </Typography>
    <Link href="/privacy-policy" >
      <Typography
        sx={linkStyle}
        variant='body18'
        className='body2-underlined-dark'>
        {t('Privacy policy')}
      </Typography>
    </Link>
  </CookieConsent>;
};

export default CookieConsentComponent;
